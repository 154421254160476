const host = "https://imart.kinlong.in:9091";
const uatHost = "https://uat.kinlong.in:8081";

let api: any = {
    api_product_index: host + `/api/v1/product/index`,
    api_product_index_uat: uatHost + `/api/v1/product/index`, // UAT
    api_traveler_postMsg: host + `/api/v1/traveler/postMsg`,
    api_product_productCategoryIndex: host + `/api/v1/product/productCategoryIndex`,
    api_product_productCategoryIndex_uat: uatHost + `/api/v1/product/productCategoryIndex`, // UAT
    api_user_career: host + `/api/v1/kinlongwebsite/userCareer`,// Production
    api_user_career_uat: uatHost + `/api/v1/kinlongwebsite/userCareer`, // UAT
    api_enquiry_submitMessage: host + `/api/v1/kinlongwebsite/submitMessage`, // Production
    api_enquiry_submitMessage_uat: uatHost + `/api/v1/kinlongwebsite/submitMessage` // UAT
};

export default api;

import React, { useState, useEffect } from 'react';
import { Navbar, Nav, Container } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import '../styles/Styles.css';
import '../styles/Bootstrap.css';
import kinLogo from '../../../images/logo.png';
import mail from '../../../images/mail01.png';
import Phone from '../../../images/phone01.png';
import India1 from '../../../images/india01.png';

const Header = () => {
    const [isScrolled, setIsScrolled] = useState(false);

    const location = useLocation();
    const currentPath = location.pathname;
    console.log(currentPath)

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 50) {
                setIsScrolled(true);
            } else {
                setIsScrolled(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <div className="boxed_wrapper ltr">
            {/* Responsive Navbar */}
            <Navbar
                collapseOnSelect
                expand="lg"
                variant="light"
                fixed="top"
                className={isScrolled ? 'scrolled-navbar' : ''}
            >
                <Container>
                    {/* Logo */}
                    <Navbar.Brand as={Link} to="/home" className="logo-container">
                        <img src={kinLogo} alt="Kin Logo" className="logo" />
                    </Navbar.Brand>

                    {/* Navbar Toggle for Mobile */}
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav">
                        {/* Centered Navigation Links */}
                        {(currentPath === '/about' || currentPath === '/contact') ?
                            <Nav className="mx-auto justify-content-center">
                                <Nav.Link as={Link} to="/home" style={!isScrolled ? { color: '#fff' } : {}}>Home</Nav.Link>
                                <Nav.Link as={Link} to="/about" style={!isScrolled ? { color: '#fff' } : {}} className={currentPath === '/about' ? (isScrolled ? "navLinkActive" : "homeActive") : ""}>About</Nav.Link>
                                <Nav.Link as={Link} to="/products" style={!isScrolled ? { color: '#fff' } : {}}>Products</Nav.Link>
                                <Nav.Link as={Link} to="/careers" style={!isScrolled ? { color: '#fff' } : {}}>Careers</Nav.Link>
                                <Nav.Link as={Link} to="/contact" className={currentPath === '/contact' ? (isScrolled ? "navLinkActive" : "homeActive") : ""} style={!isScrolled ? { color: '#fff' } : {}}>Contact</Nav.Link>
                            </Nav>
                            :
                            (currentPath === '/privacypolicy' || currentPath === '/terms' || currentPath === '/posh') ?
                                <Nav className="mx-auto justify-content-center">
                                    <Nav.Link as={Link} to="/home" style={!isScrolled ? { color: '#fff' } : {}}>Home</Nav.Link>
                                    <Nav.Link as={Link} to="/about" style={!isScrolled ? { color: '#fff' } : {}}>About</Nav.Link>
                                    <Nav.Link as={Link} to="/products" style={!isScrolled ? { color: '#fff' } : {}}>Products</Nav.Link>
                                    <Nav.Link as={Link} to="/careers" style={!isScrolled ? { color: '#fff' } : {}}>Careers</Nav.Link>
                                    <Nav.Link as={Link} to="/contact" style={!isScrolled ? { color: '#fff' } : {}}>Contact</Nav.Link>
                                </Nav>
                                :
                                <Nav className="mx-auto justify-content-center">
                                    <Nav.Link as={Link} to="/home" className={(currentPath === '/' || currentPath === '/home') ? (isScrolled ? "navLinkActive" : "homeActive") : ""}>Home</Nav.Link>
                                    <Nav.Link as={Link} to="/about" className={currentPath === '/about' ? (isScrolled ? "navLinkActive" : "homeActive") : ""}>About</Nav.Link>
                                    <Nav.Link as={Link} to="/products" className={currentPath === '/products' ? (isScrolled ? "navLinkActive" : "homeActive") : ""}>Products</Nav.Link>
                                    <Nav.Link as={Link} to="/careers" className={currentPath === '/careers' ? (isScrolled ? "navLinkActive" : "homeActive") : ""}>Careers</Nav.Link>
                                    <Nav.Link as={Link} to="/contact" className={currentPath === '/contact' ? (isScrolled ? "navLinkActive" : "homeActive") : ""}>Contact</Nav.Link>
                                </Nav>
                        }

                        <div className="d-flex align-items-center">
                            <img src={mail} alt="Mail" className="header-icon mx-2" onClick={() => window.location.href = 'mailto:inhr@kinlong.com'} />
                            <a href="tel:9108990181">
                                <img
                                    src={Phone}
                                    alt="Phone"
                                    className="header-icon mx-2"
                                />
                            </a>
                            <img src={India1} alt="India Flag" className="header-icon mx-2" />
                        </div>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </div >
    );
};

export default Header;

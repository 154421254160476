import React, { useState, useEffect } from 'react';
import { Navbar, Nav, NavDropdown, Container, Row, Col, Carousel, Card } from 'react-bootstrap';
import { FaFacebookF, FaInstagram, FaLinkedinIn, FaYoutube, FaTwitter, FaPhone, FaEnvelope } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import '../styles/Styles.css';
import '../styles/Bootstrap.css';
import facebook from '../../../images/footerface01.png';
import linkdin from '../../../images/footerlinkden01.png';
import twiter from '../../../images/fottertwiter01.png';
import youtube from '../../../images/fotteryoutube01.png';
import instagram from '../../../images/fotterinsta01.png';
import footerLogo from '../../../images/kinlongfooter01.png';
import homeicon from '../../../images/address01.png';
import phoneicon from '../../../images/footphone01.png';
import mailicon from '../../../images/footmail01.png';
import Scan from '../../../images/scan01.png';
import ios from '../../../images/applstore01.png';
import android from '../../../images/googleplay01.png';

const Footer = () => {
    const [loading, setLoading] = useState(true);

    return (
        <div className="boxed_wrapper ltr">
            <footer className="main-footer mt-5">
                <div className="widget-section p_relative pt_20 pb_10">
                    <Container>
                        <Row>
                            <Col lg={3} md={6} sm={12} className="footer-column mb_20">
                                <div className="footer-widget logo-widget mr_10">
                                    <figure className="footer-logo mb_20">
                                        <div className="widget-title mt-3">
                                            <a href="index.html">
                                                <h4 className="footerlogotext">Kinlong India Hardware</h4>
                                            </a>
                                        </div>
                                    </figure>
                                    <p style={{ color: '#000000', fontSize: '12px', textAlign: 'left', display: 'inline-block', width: '100%', lineHeight: '20px', marginTop: '3px', }}>
                                        <img src={homeicon} alt="Address" style={{ width: '20px', marginRight: '8px', verticalAlign: 'middle' }} />
                                        1st floor, Akemps Building, 1st Main Rd, Ashwini Layout, Ejipura, Bengaluru, Karnataka 560047.
                                    </p>
                                    <p style={{ color: '#000000', display: 'flex', alignItems: 'center', fontSize: '13px' }}>
                                        <img src={phoneicon} alt="Phone" style={{ width: '20px', marginRight: '8px' }} />
                                        <a href="tel:+919108990181" style={{ color: '#000000', textDecoration: 'none' }}> +91 9108990181</a>
                                    </p>
                                    <p style={{ color: '#000000', display: 'flex', alignItems: 'center', fontSize: '13px' }}>
                                        <img src={mailicon} alt="Email" style={{ width: '20px', marginRight: '8px' }} />
                                        <a href="mailto:inhr@kinlong.com.com" style={{ color: '#000000', textDecoration: 'none' }}> inhr@kinlong.com</a>
                                    </p>
                                </div>
                            </Col>

                            <Col lg={2} md={6} sm={12} className="footer-column">
                                <div className="footer-widget links-widget">
                                    <div className="widget-title mt-3">
                                        <h4 className="footerlogotext">Company</h4>
                                    </div>
                                    <div className="widget-content">
                                        <ul className="links-list clearfix">
                                            <li>
                                                <Nav.Link as={Link} to="/home" style={{ textDecoration: 'none', color: '#000000', fontSize: '15px', fontWeight: 400, lineHeight: '30px' }}>Home</Nav.Link>
                                            </li>
                                            <li>
                                                <Nav.Link as={Link} to="/about" style={{ textDecoration: 'none', color: '#000000', fontSize: '15px', fontWeight: 400, lineHeight: '30px' }}>About</Nav.Link>
                                            </li>
                                            <li>
                                                <Nav.Link as={Link} to="/products" style={{ textDecoration: 'none', color: '#000000', fontSize: '15px', fontWeight: 400, lineHeight: '30px' }}>Products</Nav.Link>
                                            </li>
                                            <li>
                                                <Nav.Link as={Link} to="/careers" style={{ textDecoration: 'none', color: '#000000', fontSize: '15px', fontWeight: 400, lineHeight: '30px' }}>Careers</Nav.Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </Col>

                            <Col lg={2} md={6} sm={12} className="footer-column">
                                <div className="footer-widget links-widget">
                                    <div className="widget-title mt-3">
                                        <h4 className="footerlogotext">Help & Support</h4>
                                    </div>
                                    <div className="widget-content">
                                        <ul className="links-list clearfix">
                                            <li>
                                                <Nav.Link as={Link} to="/contact" style={{ textDecoration: 'none', color: '#000000', fontSize: '15px', fontWeight: 400, lineHeight: '30px' }}>Contact Us</Nav.Link>
                                            </li>
                                            <li>
                                                <Nav.Link as={Link} to="/catalogue" style={{ textDecoration: 'none', color: '#000000', fontSize: '15px', fontWeight: 400, lineHeight: '30px' }}>Catalogue</Nav.Link>
                                            </li>
                                            <li>
                                                <Nav.Link as={Link} to="/privacypolicy" style={{ textDecoration: 'none', color: '#000000', fontSize: '15px', fontWeight: 400, lineHeight: '30px' }}>Privacy Policy</Nav.Link>
                                            </li>
                                            <li>
                                                <Nav.Link as={Link} to="/terms" style={{ textDecoration: 'none', color: '#000000', fontSize: '15px', fontWeight: 400, lineHeight: '30px' }}>Terms & Conditions</Nav.Link>
                                            </li>
                                            <li>
                                                <Nav.Link as={Link} to="/posh" style={{ textDecoration: 'none', color: '#000000', fontSize: '15px', fontWeight: 400, lineHeight: '30px' }}>POSH</Nav.Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </Col>


                            <Col lg={2} md={6} sm={12} className="footer-column">
                                <div className="footer-widget links-widget">
                                    <div className="widget-title mt-3">
                                        <h4 className="footerlogotext">Follow Us On</h4>
                                    </div>
                                    <div className="widget-content" style={{ display: 'flex', alignItems: 'center' }}>
                                        <a href="https://www.facebook.com/KinlongHardwareIndiaPvtLtd/" target="_blank" style={{ marginRight: '10px' }}>
                                            <img src={facebook} alt="Facebook" style={{ width: '20px', height: '20px' }} />
                                        </a>
                                        <a href="https://www.instagram.com/kinlong_india/" target="_blank" style={{ marginRight: '10px' }}>
                                            <img src={instagram} alt="Instagram" style={{ width: '20px', height: '20px' }} />
                                        </a>
                                        <a href="https://www.linkedin.com/company/kinlong-hardware-india-private-limited-official/?viewAsMember=true" target="_blank" style={{ marginRight: '10px' }}>
                                            <img src={linkdin} alt="LinkedIn" style={{ width: '20px', height: '20px' }} />
                                        </a>
                                        <a href="https://www.youtube.com/@kinlonghardwareindiapvtltd5906" target="_blank" style={{ marginRight: '10px' }}>
                                            <img src={youtube} alt="YouTube" style={{ width: '20px', height: '20px' }} />
                                        </a>
                                        <a href="https://twitter.com/GKinlongHQ" target="_blank" style={{ marginRight: '10px' }}>
                                            <img src={twiter} alt="Twitter" style={{ width: '20px', height: '20px' }} />
                                        </a>
                                    </div>
                                </div>
                            </Col>

                            <Col lg={3} md={6} sm={12} className="footer-column">
                                <div className="footer-widget links-widget">
                                    <div className="widget-title mt-3">
                                        <h4 className="footerlogotext">Get the app</h4>
                                    </div>
                                    <div className="widget-content">
                                        <ul className="links-list clearfix" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', width: '100%' }}>
                                            <div style={{ flex: '1 1 60%', marginRight: '10px' }}>
                                                <a href="your-scan-link-here" target="_blank">
                                                    <img src={Scan} alt="Scan QR Code" className="" style={{ width: '79%', height: '100%' }} />
                                                </a>
                                            </div>
                                            <div style={{ flex: '0 1 59%' }}>
                                                <div style={{ marginBottom: '10px' }}>
                                                    <a href="https://play.google.com/store/apps/details?id=com.reactnative67&pli=1" target="_blank">
                                                        <img src={android} alt="android" className="" style={{ width: '90%' }} />
                                                    </a>
                                                </div>
                                                <div>
                                                    <a href="https://apps.apple.com/us/app/id1524012445" target="_blank">
                                                        <img src={ios} alt="apple" className="" style={{ width: '90%' }} />
                                                    </a>
                                                </div>
                                            </div>
                                        </ul>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                    <Container fluid>
                        <div className='copyright'>
                            <p className='copyrighttext'>Copyright © 2025 Kinlong India Hardware Pvt Ltd All Rights Reserved</p>
                        </div>
                    </Container>
                </div>
            </footer>

        </div>
    );
}

export default Footer;
import React, { useState, useEffect } from 'react';
import { Navbar, Nav, NavDropdown, Container, Row, Col, Form, Button, Carousel, Card, Spinner } from 'react-bootstrap';
import { FaFacebookF, FaInstagram, FaLinkedinIn, FaYoutube, FaTwitter, FaPhone, FaEnvelope } from 'react-icons/fa';
import { Breadcrumb } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Header from '../home/Header';
import Footer from '../home/Footer';
import '../styles/Styles.css';
import '../styles/Bootstrap.css';
import contact from '../../../images/bg01.jpg';
import whatsappIcon from '../../../images/whatspp.png';
import whatsappbussinesIcon from '../../../images/whastappb01.png';
import MAP from '../../../images/contmap01.png';
import emailicon from '../../../images/email.png';
import SendArrow from '../../../images/arrow.png';
import Cardbg from '../../../images/formbg.png';
import Contactbg from '../../../images/contactbg01.png';
import Mapcard from '../../../images/mapconright01.png';
import twittericon from '../../../images/twitter.png';
// import about from '../../../images/ab01.jpg';
import about from '../../../images/banner01.png';
import Arrow from './Arrow';
import axios from 'axios';
import api from '../config/api';
import { NONAME } from 'dns';
// import { post } from '../../../utils/HttpService';

const Contact = () => {
    const [loading, setLoading] = useState(false);
    const [spinnerFlag, setSpinnerFlag] = useState(false);
    const [contactName, setContactName] = useState('');
    const [email, setEmail] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [message, setMessage] = useState('');
    const [responseMessage, setResponseMessage] = useState<string | null>(null);

    useEffect(() => {
        const timer = setTimeout(() => {
            setLoading(false);
        }, 1000);

        return () => clearTimeout(timer);
    }, []);

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setSpinnerFlag(true);

        const trimmedName = contactName.trim();
        const trimmedEmail = email.trim();
        const trimmedPhone = phoneNumber.trim();
        const trimmedMessage = message.trim();

        // Email validation
        const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(trimmedEmail);
        if (!isValidEmail) {
            setResponseMessage('Please enter a valid email address.');
            setSpinnerFlag(false);
            return;
        }

        // Prepare the payload
        const payload = {
            user_name: trimmedName,
            user_email: trimmedEmail,
            phone: trimmedPhone,
            message: trimmedMessage
        };

        // API call
        axios.post(api.api_enquiry_submitMessage, payload, {
            headers: {
                'Content-Type': 'application/json',
            }
        })
            .then(res => {
                if (res.status === 200 && res.data.message === "Your enquiry has been submitted successfully.") {
                    setResponseMessage('Message submitted successfully!');
                    setMessage('');
                    setContactName('');
                    setPhoneNumber('');
                    setEmail('');
                } else {
                    setResponseMessage('Failed to submit message. Please try again.');
                }
            })
            .catch(err => {
                console.error("Error details:", err.response?.data || err.message || err);
                const errorDetails = err.response?.data?.errors || {};
                setResponseMessage(
                    err.response?.data?.message ||
                    'An error occurred. Details: ' + JSON.stringify(errorDetails)
                );
            })
            .finally(() => {
                setSpinnerFlag(false);
            });
    };



    return (
        <div className="boxed_wrapper ltr">
            {loading ? (
                <div className="loader-wrap">
                    <div className="preloader">
                        <div id="handle-preloader" className="handle-preloader">
                            <div className="animation-preloader">
                                <div className="spinner"></div>
                                <div className="txt-loading">
                                    {['K', 'I', 'N', 'L', 'O', 'N', 'G'].map((letter, index) => (
                                        <span key={index} data-text-preloader={letter} className="letters-loading">
                                            {letter}
                                        </span>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <>

                    <Header />

                    {/* Breadcrumb */}
                    <div className="breadcrumb-section" style={{ position: 'relative', padding: '4.5rem 0 1.5rem 0', textAlign: 'center' }}>
                        <div
                            style={{
                                backgroundImage: `url(${Contactbg})`,
                                backgroundSize: 'cover',
                                backgroundPosition: 'center',
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                right: 0,
                                bottom: 0,
                                zIndex: 1,
                            }}
                        ></div>
                        <div
                            style={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                right: 0,
                                bottom: 0,
                                zIndex: 2,
                            }}
                        ></div>
                        <Container className='mt-4' style={{ position: 'relative', zIndex: 3, }}>
                            <Row>
                                <Col md={6}>
                                    <Card
                                        style={{
                                            textAlign: 'center',
                                            borderRadius: '1em',
                                            maxWidth: '32rem',
                                            margin: '0 auto',
                                            padding: '1.25rem 1.25rem 1.1rem 1.25rem ',
                                            position: 'relative',
                                            backgroundImage: `url(${Cardbg})`,
                                            backgroundSize: 'cover',
                                            backgroundPosition: 'center',
                                            color: '#ffffff',
                                        }}
                                    >
                                        <Card.Body>
                                            <Row className="align-items-center">
                                                <Form className="mb-3 ps-4" onSubmit={handleSubmit} >
                                                    <Row className="d-flex align-items-center mb-3">
                                                        <Col md={8} className="text-start">
                                                            <h2 className="mt-3" style={{ color: '#ffffff', fontWeight: '700', fontSize: '1.8rem', fontFamily: 'Montserrat' }}>Enquiry</h2>
                                                        </Col>
                                                        <Col md={4} className="text-end">
                                                            {spinnerFlag ? (
                                                                <Spinner animation="border" role="status" variant="primary" />
                                                            ) : (
                                                                <Button
                                                                    variant="primary"
                                                                    type="submit"
                                                                    style={{
                                                                        backgroundColor: '#8EFBFF',
                                                                        borderRadius: '20px',
                                                                        border: 'none',
                                                                        display: 'flex',
                                                                        padding: '0.375rem 1.25rem',
                                                                        alignItems: 'center',
                                                                        justifyContent: 'center',
                                                                        color: '#02489F',
                                                                        fontWeight: '600',
                                                                        fontFamily: 'Montserrat',
                                                                        fontSize: '0.875rem',
                                                                    }}
                                                                >
                                                                    SEND
                                                                    <img
                                                                        src={SendArrow}
                                                                        alt="Arrow"
                                                                        style={{
                                                                            marginLeft: '0.3rem',
                                                                            width: '0.8rem',
                                                                            height: '0.8rem',
                                                                        }}
                                                                    />
                                                                </Button>

                                                            )}
                                                        </Col>
                                                    </Row>
                                                    <Form.Group as={Row} controlId="formName" className="mb-3 align-items-center">
                                                        <Col md={3} className="text-start">
                                                            <Form.Label style={{ fontFamily: 'Montserrat' }}>
                                                                Name <span style={{ color: 'red' }}>*</span>
                                                            </Form.Label>
                                                        </Col>
                                                        <Col md={8}>
                                                            <Form.Control
                                                                type="text"
                                                                placeholder="Enter your name"
                                                                value={contactName}
                                                                onChange={(e) => setContactName(e.target.value)}
                                                                required
                                                            />
                                                        </Col>
                                                    </Form.Group>

                                                    <Form.Group as={Row} controlId="formEmail" className="mb-3 align-items-center">
                                                        <Col md={3} className="text-start">
                                                            <Form.Label style={{ fontFamily: 'Montserrat' }}>
                                                                Email <span style={{ color: 'red' }}>*</span>
                                                            </Form.Label>
                                                        </Col>
                                                        <Col md={8}>
                                                            <Form.Control
                                                                type="email"
                                                                placeholder="Enter your email"
                                                                value={email}
                                                                onChange={(e) => setEmail(e.target.value)}
                                                                required
                                                            />
                                                        </Col>
                                                    </Form.Group>

                                                    <Form.Group as={Row} controlId="formPhone" className="mb-3 align-items-center">
                                                        <Col md={3} className="text-start">
                                                            <Form.Label style={{ fontFamily: 'Montserrat' }}>
                                                                Phone <span style={{ color: 'red' }}>*</span>
                                                            </Form.Label>
                                                        </Col>
                                                        <Col md={8}>
                                                            <Form.Control
                                                                type="tel"
                                                                placeholder="Enter your phone number"
                                                                value={phoneNumber}
                                                                onChange={(e) => setPhoneNumber(e.target.value)}
                                                                required
                                                            />
                                                        </Col>
                                                    </Form.Group>

                                                    <Form.Group as={Row} controlId="formMessage" className="align-items-center">
                                                        <Col md={3} className="text-start">
                                                            <Form.Label style={{ fontFamily: 'Montserrat' }}>Message</Form.Label>
                                                        </Col>
                                                        <Col md={8}>
                                                            <Form.Control
                                                                as="textarea"
                                                                rows={3}
                                                                placeholder="Your message"
                                                                value={message}
                                                                onChange={(e) => setMessage(e.target.value)}
                                                            />
                                                        </Col>
                                                    </Form.Group>
                                                </Form>
                                                {responseMessage && <p style={{ color: '#ffffff' }}>{responseMessage}</p>}
                                            </Row>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                        </Container>

                    </div>

                    <section className='offices mt-5'>
                        <Container fluid className='p-0 mt-3'>
                            <h2 className="contacts-slide-in">
                                Kinlong India <span style={{ color: '#2671ae' }}>Offices</span>
                            </h2>
                        </Container>
                    </section>

                    <section className='locations'>
                        <Container className='p-0 mt-5'>
                            <Row className="d-flex justify-content-center">
                                <Col lg={4} md={6} sm={12} className="mb-4 d-flex justify-content-center">
                                    <Card className="contact-card">
                                        <Card.Body>
                                            <Row className="align-items-center">
                                                <Col xs={12} className="text-left">
                                                    <Card.Title>
                                                        <img src={MAP} alt="Map" style={{ height: '2.1rem', verticalAlign: 'middle', marginRight: '0.4rem' }} />   Bengaluru
                                                    </Card.Title>
                                                </Col>
                                            </Row>
                                            <Card.Text>
                                                <span style={{ fontWeight: '600', paddingRight: '0.5rem', color: '#000' }}>Address:</span>
                                                1st floor, Akemps Building, 1st Main Rd, Ashwini Layout, Ejipura, Bengaluru, Karnataka 560047
                                                <div className='p-0 mt-3' style={{ textAlign: 'right' }}>
                                                    <a
                                                        href="https://www.google.com/maps/place/Kinlong+Hardware+India+Pvt+Ltd./@12.9392155,77.6261285,17z/data=!3m1!4b1!4m6!3m5!1s0x3bae15d642c1d089:0x3947cd84d2fc821b!8m2!3d12.9392155!4d77.6287034!16s%2Fg%2F11cn7wm7g3?entry=ttu&g_ep=EgoyMDI0MTIxMS4wIKXMDSoASAFQAw%3D%3D"
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                        className='map-box'
                                                    >
                                                        Map
                                                        {/* <img src={Mapcard} alt="Map" style={{ width: '10px', height: '10px' }} /> */}
                                                    </a>
                                                </div>
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Col>

                                <Col lg={4} md={6} sm={12} className="mb-4 d-flex justify-content-center">
                                    <Card className="contact-card">
                                        <Card.Body>
                                            <Row className="align-items-center">
                                                <Col xs={12} className="text-left">
                                                    <Card.Title>
                                                        <img src={MAP} alt="Map" style={{ height: '2.1rem', verticalAlign: 'middle', marginRight: '0.4rem' }} />  Chennai
                                                    </Card.Title>
                                                </Col>
                                            </Row>
                                            <Card.Text>
                                                <span style={{ fontWeight: '600', paddingRight: '0.5rem', color: '#000' }}>Address:</span>
                                                 113, 170, Rangarajapuram Main Rd, KG NTR Heritage,Viswanatha Puram, Kodambakkam, Chennai,Tamil Nadu 600024.
                                                <div className='p-0 mt-3' style={{ textAlign: 'right' }}>
                                                    <a
                                                        href="https://www.google.com/maps/place/Kinlong+Hardware+India+Pvt+Ltd./@13.0485471,80.2190345,17z/data=!3m1!4b1!4m6!3m5!1s0x3a52677e6dc20277:0xf73059983157d0a4!8m2!3d13.0485419!4d80.2216094!16s%2Fg%2F11y3mbnr3y?entry=ttu&g_ep=EgoyMDI0MTIxMS4wIKXMDSoASAFQAw%3D%3D"
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                        className='map-box'
                                                    >
                                                        Map
                                                        {/* <img src={Mapcard} alt="Map" style={{ width: '10px', height: '10px' }} /> */}
                                                    </a>
                                                </div>
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Col>

                                <Col lg={4} md={6} sm={12} className="mb-4 d-flex justify-content-center">
                                    <Card className="contact-card">
                                        <Card.Body>
                                            <Row className="align-items-center">
                                                <Col xs={12} className="text-left">
                                                    <Card.Title>
                                                        <img src={MAP} alt="Map" style={{ height: '2.1rem', verticalAlign: 'middle', marginRight: '0.4rem' }} /> Visakhapatnam
                                                    </Card.Title>
                                                </Col>
                                            </Row>
                                            <Card.Text>
                                                <span style={{ fontWeight: '600', paddingRight: '0.5rem', color: '#000' }}>Address:</span>
                                                # Flat no:- C2, Ville Royal Apartments,3Opposite VUDA Childrens Theatre,Siripuram, Visakhapatnam-530003.
                                                <div className='p-0 mt-3' style={{ textAlign: 'right' }}>
                                                    <a
                                                        href="https://www.google.com/maps?ll=17.720247,83.319921&z=15&t=m&hl=en&gl=IN&mapclient=embed&cid=2863753498449707614"
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                        className='map-box'
                                                    >
                                                        Map
                                                        {/* <img src={Mapcard} alt="Map" style={{ width: '10px', height: '10px' }} /> */}
                                                    </a>
                                                </div>
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Col>

                            </Row>
                            <Row className="d-flex justify-content-center">
                                {/* Card 1 */}
                                <Col lg={4} md={6} sm={12} className="mb-4 d-flex justify-content-center">
                                    <Card className="contact-card">
                                        <Card.Body>
                                            <Row className="align-items-center">
                                                <Col xs={12} className="text-left">
                                                    <Card.Title>
                                                        <img src={MAP} alt="Map" style={{ height: '2.1rem', verticalAlign: 'middle', marginRight: '0.4rem' }} />  Hyderabad
                                                    </Card.Title>
                                                </Col>
                                            </Row>
                                            <Card.Text>
                                                <span style={{ fontWeight: '600', paddingRight: '0.5rem', color: '#000' }}>Address:</span>
                                                Flat no 512, 4th floor,KTC Illumination, Vittal Rao Nagar,HITEC City, Hyderabad, Telangana-500081
                                                <div className='p-0 mt-3' style={{ textAlign: 'right' }}>
                                                    <a
                                                        href="https://www.google.com/maps?ll=17.441045,78.382649&z=11&t=m&hl=en&gl=IN&mapclient=embed&cid=16543754990101667674"
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                        className='map-box'
                                                    >
                                                        Map
                                                        {/* <img src={Mapcard} alt="Map" style={{ width: '10px', height: '10px' }} /> */}
                                                    </a>
                                                </div>
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Col>

                                {/* Card 2 */}
                                <Col lg={4} md={6} sm={12} className="mb-4 d-flex justify-content-center">
                                    <Card className="contact-card">
                                        <Card.Body>
                                            <Row className="align-items-center">
                                                <Col xs={12} className="text-left">
                                                    <Card.Title>
                                                        <img src={MAP} alt="Map" style={{ height: '2.1rem', verticalAlign: 'middle', marginRight: '0.4rem' }} />  Kochi
                                                    </Card.Title>
                                                </Col>
                                            </Row>
                                            <Card.Text>
                                                <span style={{ fontWeight: '600', paddingRight: '0.5rem', color: '#000' }}>Address:</span>
                                                Kin Long Hardware (India) Pvt Ltd. 1st Floor, Balakrishna Arcade, Ratna Lane,South Janatha Road, Palarivattom - 682025
                                                <div className='p-0 mt-3' style={{ textAlign: 'right' }}>
                                                    <a
                                                        href="https://www.google.com/maps?ll=10.000409,76.303018&z=15&t=m&hl=en&gl=IN&mapclient=embed&cid=9997849755723346394"
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                        className='map-box'
                                                    >
                                                        Map
                                                        {/* <img src={Mapcard} alt="Map" style={{ width: '10px', height: '10px' }} /> */}
                                                    </a>
                                                </div>
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Col>

                                <Col lg={4} md={6} sm={12} className="mb-4 d-flex justify-content-center">
                                    <Card className="contact-card">
                                        <Card.Body>
                                            <Row className="align-items-center">
                                                <Col xs={12} className="text-left">
                                                    <Card.Title style={{ fontWeight: 'bold', fontSize: '1.2em', color: '#1C56B2' }}>
                                                        <img src={MAP} alt="Map" style={{ height: '2.1rem', verticalAlign: 'middle', marginRight: '0.4rem' }} />  Calicut
                                                    </Card.Title>
                                                </Col>
                                            </Row>
                                            <Card.Text>
                                                <span style={{ fontWeight: '600', paddingRight: '0.5rem', color: '#000' }}>Address:</span>
                                                Kin Long Hardware (India) Pvt Ltd.3.C, Royal luxury Apartments,West Nadakkave, Vandipetta,Vellayil, Kozhikode, Kerala-673011
                                                <div className='p-0 mt-3' style={{ textAlign: 'right' }}>
                                                    <a
                                                        href="https://www.google.com/maps?ll=10.000409,76.303018&z=15&t=m&hl=en&gl=IN&mapclient=embed&cid=9997849755723346394"
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                        className='map-box'
                                                    >
                                                        Map
                                                        {/* <img src={Mapcard} alt="Map" style={{ width: '10px', height: '10px' }} /> */}
                                                    </a>
                                                </div>
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Col>

                            </Row>

                            <Row className="d-flex justify-content-center">
                                {/* Card 1 */}
                                <Col lg={4} md={6} sm={12} className="mb-4 d-flex justify-content-center">
                                    <Card className="contact-card">
                                        <Card.Body>
                                            <Row className="align-items-center">
                                                <Col xs={12} className="text-left">
                                                    <Card.Title style={{ fontWeight: 'bold', fontSize: '1.2em', color: '#1C56B2' }}>
                                                        <img src={MAP} alt="Map" style={{ height: '2.1rem', verticalAlign: 'middle', marginRight: '0.4rem' }} />  Mumbai
                                                    </Card.Title>
                                                </Col>
                                            </Row>
                                            <Card.Text >
                                                <span style={{ fontWeight: '600', paddingRight: '0.5rem', color: '#000' }}>Address:</span>
                                                211, C wing Rupa Solitaire,Sector - 1,Millennium Business Park,Thane Belapur Road,MAHAPE - 400710 Ships Mumbai
                                                <div className='p-0 mt-3' style={{ textAlign: 'right' }}>
                                                    <a
                                                        href="https://www.google.com/maps?ll=19.110978,73.015743&z=15&t=h&hl=en&gl=IN&mapclient=embed&cid=10407487248210261359"
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                        className='map-box'
                                                    >
                                                        Map
                                                        {/* <img src={Mapcard} alt="Map" style={{ width: '10px', height: '10px' }} /> */}
                                                    </a>
                                                </div>
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Col>

                                {/* Card 2 */}
                                <Col lg={4} md={6} sm={12} className="mb-4 d-flex justify-content-center">
                                    <Card className="contact-card">
                                        <Card.Body>
                                            <Row className="align-items-center">
                                                <Col xs={12} className="text-left">
                                                    <Card.Title style={{ fontWeight: 'bold', fontSize: '1.2em', color: '#1C56B2' }}>
                                                        <img src={MAP} alt="Map" style={{ height: '2.1rem', verticalAlign: 'middle', marginRight: '0.4rem' }} />  Pune
                                                    </Card.Title>
                                                </Col>
                                            </Row>
                                            <Card.Text >
                                                <span style={{ fontWeight: '600', paddingRight: '0.5rem', color: '#000' }}>Address:</span>
                                                Wing C, Flat No. 401, Whistling Meadows Society,Near Marigold Banquet, Bavdhan-Bhugaon, Pune-(411021)412115
                                                <div className='p-0 mt-3' style={{ textAlign: 'right' }}>
                                                    <a
                                                        href="https://www.google.com/maps?ll=18.506669,73.761201&z=15&t=m&hl=en&gl=IN&mapclient=embed&cid=13483414694007757014"
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                        className='map-box'
                                                    >
                                                        Map
                                                        {/* <img src={Mapcard} alt="Map" style={{ width: '10px', height: '10px' }} /> */}
                                                    </a>
                                                </div>
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Col>

                                <Col lg={4} md={6} sm={12} className="mb-4 d-flex justify-content-center">
                                    <Card className="contact-card">
                                        <Card.Body>
                                            <Row className="align-items-center">
                                                <Col xs={12} className="text-left">
                                                    <Card.Title style={{ fontWeight: 'bold', fontSize: '1.2em', color: '#1C56B2' }}>
                                                        <img src={MAP} alt="Map" style={{ height: '2.1rem', verticalAlign: 'middle', marginRight: '0.4rem' }} />  Kolkata
                                                    </Card.Title>
                                                </Col>
                                            </Row>
                                            <Card.Text >
                                                <span style={{ fontWeight: '600', paddingRight: '0.5rem', color: '#000' }}>Address:</span>
                                                Kin Long Hardware (India) Pvt Ltd.DLF GALLERIA,Unit No-DL 1010,10th Floor, Action Area 1 ,Newtown, Kolkata,WestBengal-700156
                                                <div className='p-0 mt-3' style={{ textAlign: 'right' }}>
                                                    <a
                                                        href="https://www.google.com/maps?ll=22.58167,88.460358&z=13&t=m&hl=en&gl=IN&mapclient=embed&cid=2188340075208470318"
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                        className='map-box'
                                                    >
                                                        Map
                                                        {/* <img src={Mapcard} alt="Map" style={{ width: '10px', height: '10px' }} /> */}
                                                    </a>
                                                </div>
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>

                            <Row className="d-flex justify-content-center">
                                {/* Card 1 */}
                                <Col lg={4} md={6} sm={12} className="mb-4 d-flex justify-content-center">
                                    <Card className="contact-card">
                                        <Card.Body>
                                            <Row className="align-items-center">
                                                <Col xs={12} className="text-left">
                                                    <Card.Title style={{ fontWeight: 'bold', fontSize: '1.2em', color: '#1C56B2' }}>
                                                        <img src={MAP} alt="Map" style={{ height: '2.1rem', verticalAlign: 'middle', marginRight: '0.4rem' }} />  Odisha
                                                    </Card.Title>
                                                </Col>
                                            </Row>
                                            <Card.Text style={{ fontSize: '0.825rem' }}>
                                                <span style={{ fontWeight: '600', paddingRight: '0.5rem', color: '#000' }}>Address:</span>
                                                O-Launch Pad, HIG 45 Infront of Durga Mandap, Bhubaneswar Odisha Bhubaneshwar - 751013
                                                <div className='p-0 mt-3' style={{ textAlign: 'right' }}>
                                                    <a
                                                        href="https://www.google.com/maps?ll=20.295575,85.824608&z=15&t=m&hl=en&gl=IN&mapclient=embed&cid=10933906730170397274"
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                        className='map-box'
                                                    >
                                                        Map
                                                        {/* <img src={Mapcard} alt="Map" style={{ width: '10px', height: '10px' }} /> */}
                                                    </a>
                                                </div>
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Col>

                                {/* Card 2 */}
                                <Col lg={4} md={6} sm={12} className="mb-4 d-flex justify-content-center">
                                    <Card className="contact-card">
                                        <Card.Body>
                                            <Row className="align-items-center">
                                                <Col xs={12} className="text-left">
                                                    <Card.Title style={{ fontWeight: 'bold', fontSize: '1.2em', color: '#1C56B2' }}>
                                                        <img src={MAP} alt="Map" style={{ width: '25px', height: '29px', verticalAlign: 'middle' }} />  Jaipur
                                                    </Card.Title>
                                                </Col>
                                            </Row>
                                            <Card.Text >
                                                <span style={{ fontWeight: '600', paddingRight: '0.5rem', color: '#000' }}>Address:</span>
                                                #Ground Floor, Office No G - 08,Metropolis Tower, Near Purani Chungi,Ajmer Road, Jaipur,Rajasthan - 302019.
                                                <div className='p-0 mt-3' style={{ textAlign: 'right' }}>
                                                    <a
                                                        href="https://www.google.com/maps?ll=26.896116,75.755749&z=13&t=m&hl=en&gl=IN&mapclient=embed&cid=14204346083866700084"
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                        className='map-box'
                                                    >
                                                        Map
                                                        {/* <img src={Mapcard} alt="Map" style={{ width: '10px', height: '10px' }} /> */}
                                                    </a>
                                                </div>
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Col>

                                <Col lg={4} md={6} sm={12} className="mb-4 d-flex justify-content-center">
                                    <Card className="contact-card">
                                        <Card.Body>
                                            <Row className="align-items-center">
                                                <Col xs={12} className="text-left">
                                                    <Card.Title style={{ fontWeight: 'bold', fontSize: '1.2em', color: '#1C56B2' }}>
                                                        <img src={MAP} alt="Map" style={{ width: '25px', height: '29px', verticalAlign: 'middle' }} />  Gurgoan
                                                    </Card.Title>
                                                </Col>
                                            </Row>
                                            <Card.Text >
                                                <span style={{ fontWeight: '600', paddingRight: '0.5rem', color: '#000' }}>Address:</span>
                                                Parshavnath Arcadia Building , 5th Floor ,M.G Road , Sector -14 , Gurugram, Haryana ,PIN-122001 (above MI Mobile Service Center)
                                                <div className='p-0 mt-3' style={{ textAlign: 'right' }}>
                                                    <a
                                                        href="https://www.google.com/maps?ll=28.470976,77.049667&z=15&t=m&hl=en&gl=IN&mapclient=embed&cid=11328953769398156612"
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                        className='map-box'
                                                    >
                                                        Map
                                                        {/* <img src={Mapcard} alt="Map" style={{ width: '10px', height: '10px' }} /> */}
                                                    </a>
                                                </div>
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>

                            <Row className="d-flex justify-content-center">
                                {/* Card 1 */}
                                <Col lg={4} md={6} sm={12} className="mb-4 d-flex justify-content-center">
                                    <Card className="contact-card">
                                        <Card.Body>
                                            <Row className="align-items-center">
                                                <Col xs={12} className="text-left">
                                                    <Card.Title style={{ fontWeight: 'bold', fontSize: '1.2em', color: '#1C56B2' }}>
                                                        <img src={MAP} alt="Map" style={{ width: '25px', height: '29px', verticalAlign: 'middle' }} />  Noida
                                                    </Card.Title>
                                                </Col>
                                            </Row>
                                            <Card.Text >
                                                <span style={{ fontWeight: '600', paddingRight: '0.5rem', color: '#000' }}>Address:</span>
                                                Kin Long Hardware (India) Pvt Ltd.Unit 815, Astralist (Supernova),Sector 94, Noida,Uttar Pradesh - 201313.
                                                <div className='p-0 mt-3' style={{ textAlign: 'right' }}>
                                                    <a
                                                        href="https://www.google.com/maps?ll=28.550809,77.324497&z=15&t=m&hl=en&gl=IN&mapclient=embed&cid=5935650754354676256"
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                        className='map-box'
                                                    >
                                                        Map
                                                        {/* <img src={Mapcard} alt="Map" style={{ width: '10px', height: '10px' }} /> */}
                                                    </a>
                                                </div>
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Col>

                                {/* Card 2 */}
                                <Col lg={4} md={6} sm={12} className="mb-4 d-flex justify-content-center">
                                    <Card className="contact-card">
                                        <Card.Body>
                                            <Row className="align-items-center">
                                                <Col xs={12} className="text-left">
                                                    <Card.Title style={{ fontWeight: 'bold', fontSize: '1.2em', color: '#1C56B2' }}>
                                                        <img src={MAP} alt="Map" style={{ width: '25px', height: '29px', verticalAlign: 'middle' }} />  Ahmedabad
                                                    </Card.Title>
                                                </Col>
                                            </Row>
                                            <Card.Text >
                                                <span style={{ fontWeight: '600', paddingRight: '0.5rem', color: '#000' }}>Address:</span>
                                                D-1114, Titanium City Center,Nr. Sachin Tower, 100 Ft Road,Anandnagar, Ahmedabad,Gujarat - 380015
                                                <div className='p-0 mt-3' style={{ textAlign: 'right' }}>
                                                    <a
                                                        href="https://www.google.com/maps?ll=23.011548,72.523741&z=15&t=m&hl=en&gl=IN&mapclient=embed&q=BLOCK-D+TITANIUM+CITY+CENTER+BUSINESS+PARK+Jodhpur+Village+Ahmedabad,+Gujarat+380015"
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                        className='map-box'
                                                    >
                                                        Map
                                                        {/* <img src={Mapcard} alt="Map" style={{ width: '10px', height: '10px' }} /> */}
                                                    </a>
                                                </div>
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Col>

                                <Col lg={4} md={6} sm={12} className="mb-4 d-flex justify-content-center">
                                    <Card className="contact-card">
                                        <Card.Body>
                                            <Row className="align-items-center">
                                                <Col xs={12} className="text-left">
                                                    <Card.Title style={{ fontWeight: 'bold', fontSize: '1.2em', color: '#1C56B2' }}>
                                                        <img src={MAP} alt="Map" style={{ width: '25px', height: '29px', verticalAlign: 'middle' }} />  Mohali
                                                    </Card.Title>
                                                </Col>
                                            </Row>
                                            <Card.Text >
                                                <span style={{ fontWeight: '600', paddingRight: '0.5rem', color: '#000' }}>Address:</span>
                                                Kin Long Hardware (India) Pvt Ltd.F-468, 1st floor, Phase-8 B, Sector-91,Mohali, Punjab- 160071
                                                <div className='p-0 mt-3' style={{ textAlign: 'right' }}>
                                                    <a
                                                        href="https://www.google.com/maps/place/Kinlong+Hardware+India+Pvt.+Ltd./@30.696914,76.687631,2400m/data=!3m1!1e3!4m6!3m5!1s0x390feee29d21900f:0x2baab6ce1c6cda86!8m2!3d30.6969136!4d76.687631!16s%2Fg%2F11f54rcxfz?hl=en&entry=ttu&g_ep=EgoyMDI0MTIxMS4wIKXMDSoASAFQAw%3D%3D"
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                        className='map-box'
                                                    >
                                                        Map
                                                        {/* <img src={Mapcard} alt="Map" style={{ width: '10px', height: '10px' }} /> */}
                                                    </a>
                                                </div>
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>

                            <Row className="d-flex">
                                {/* Card 1 */}
                                <Col lg={4} md={6} sm={12} className="mb-4 d-flex justify-content-center">
                                    <Card className="contact-card">
                                        <Card.Body>
                                            <Row className="align-items-center">
                                                <Col xs={12} className="text-left">
                                                    <Card.Title style={{ fontWeight: 'bold', fontSize: '1.2em', color: '#1C56B2' }}>
                                                        <img src={MAP} alt="Map" style={{ width: '25px', height: '29px', verticalAlign: 'middle' }} />  Patna
                                                    </Card.Title>
                                                </Col>
                                            </Row>
                                            <Card.Text >
                                                <span style={{ fontWeight: '600', paddingRight: '0.5rem', color: '#000' }}>Address:</span>
                                                Office no. D-217, 2nd floor Dumraon palace, Fraser road, near Dak Bunglow Chauraha, Patna - 80001 Bihar
                                                <div className='p-0 mt-3' style={{ textAlign: 'right' }}>
                                                    <a
                                                        href="https://www.google.com/maps?ll=25.610962,85.137617&z=17&t=m&hl=en&gl=IN&mapclient=embed&cid=18390124023515502918"
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                        className='map-box'
                                                    >
                                                        Map
                                                        {/* <img src={Mapcard} alt="Map" style={{ width: '10px', height: '10px' }} /> */}
                                                    </a>
                                                </div>
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                        </Container>
                    </section>


                    <section className='wharehouse'>
                        <Container fluid className='p-0 mt-3'>
                            <h2 className="contacts-slide-in" style={{ fontWeight: '600' }}>
                                Kinlong India  <span style={{ color: '#2671ae' }}>Warehouse</span>
                            </h2>
                        </Container>
                    </section>

                    <section className='locations'>
                        <Container className='p-0 mt-5'>
                            <Row className="d-flex justify-content-center">
                                {/* Card 1 */}
                                <Col lg={4} md={6} sm={12} className="mb-4 d-flex justify-content-center">
                                    <Card className="contact-card">
                                        <Card.Body>
                                            <Row className="align-items-center">
                                                <Col xs={12} className="text-left">
                                                    <Card.Title style={{ fontWeight: 'bold', fontSize: '1.2em', color: '#1C56B2' }}>
                                                        <img src={MAP} alt="Map" style={{ width: '25px', height: '29px', verticalAlign: 'middle' }} />  Bengaluru Warehouse
                                                    </Card.Title>
                                                </Col>
                                            </Row>
                                            <Card.Text >
                                                <span style={{ fontWeight: '600', paddingRight: '0.5rem', color: '#000' }}>Address:</span>
                                                Survey No.48/3,Bommanahalli Village ,Kasaba Hobli, Tumkur Road,Nelamangala Taluk Bangalore Rural,Karnataka - 562123
                                                <div className='p-0 mt-3' style={{ textAlign: 'right' }}>
                                                    <a
                                                        href="https://www.google.com/maps/place/KINLONG+HARDWARE+NEW+WAREHOUSE/@13.125727,77.363406,2719m/data=!3m1!1e3!4m6!3m5!1s0x3bae2f4f7cc85725:0x85b71a05eb0d9291!8m2!3d13.1257266!4d77.3634064!16s%2Fg%2F11hbv9r9rw?hl=en&entry=ttu&g_ep=EgoyMDI0MTIxMS4wIKXMDSoASAFQAw%3D%3D"
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                        className='map-box'
                                                    >
                                                        Map
                                                        {/* <img src={Mapcard} alt="Map" style={{ width: '10px', height: '10px' }} /> */}
                                                    </a>
                                                </div>
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Col>

                                {/* Card 2 */}
                                <Col lg={4} md={6} sm={12} className="mb-4 d-flex justify-content-center">
                                    <Card className="contact-card">
                                        <Card.Body>
                                            <Row className="align-items-center">
                                                <Col xs={12} className="text-left">
                                                    <Card.Title style={{ fontWeight: 'bold', fontSize: '1.2em', color: '#1C56B2' }}>
                                                        <img src={MAP} alt="Map" style={{ width: '25px', height: '29px', verticalAlign: 'middle' }} />  Chennai Warehouse
                                                    </Card.Title>
                                                </Col>
                                            </Row>
                                            <Card.Text >
                                                <span style={{ fontWeight: '600', paddingRight: '0.5rem', color: '#000' }}>Address:</span>
                                                Kin Long Hardware (India) Pvt Ltd.Door No.90, G.N.T Road, Ponnammanmedu, Madhavaram, Chennai-600060
                                                <div className='p-0 mt-3' style={{ textAlign: 'right' }}>
                                                    <a
                                                        href="https://www.google.com/maps?ll=13.145239,80.218003&z=15&t=m&hl=en&gl=IN&mapclient=embed&q=90,+Grand+Northern+Trunk+Rd+Madhavaram+Chennai,+Tamil+Nadu+600110"
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                        className='map-box'
                                                    >
                                                        Map
                                                        {/* <img src={Mapcard} alt="Map" style={{ width: '10px', height: '10px' }} /> */}
                                                    </a>
                                                </div>
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Col>

                                <Col lg={4} md={6} sm={12} className="mb-4 d-flex justify-content-center">
                                    <Card className="contact-card">
                                        <Card.Body>
                                            <Row className="align-items-center">
                                                <Col xs={12} className="text-left">
                                                    <Card.Title style={{ fontWeight: 'bold', fontSize: '1.2em', color: '#1C56B2' }}>
                                                        <img src={MAP} alt="Map" style={{ width: '25px', height: '29px', verticalAlign: 'middle' }} />  Gurgaon  Warehouse
                                                    </Card.Title>
                                                </Col>
                                            </Row>
                                            <Card.Text >
                                                <span style={{ fontWeight: '600', paddingRight: '0.5rem', color: '#000' }}>Address:</span>
                                                Kin Long Hardware (India) Pvt Ltd. Regd., 20//7/1,Village Jamalpur, Tehsil Farukhnagar,District Gurgaon, Haryana, 122503.
                                                <div className='p-0 mt-3' style={{ textAlign: 'right' }}>
                                                    <a
                                                        href="https://www.google.com/maps?ll=28.369028,76.849694&z=15&t=m&hl=en&gl=IN&mapclient=embed&q=28%C2%B022%2708.5%22N+76%C2%B050%2758.9%22E+28.369028,+76.849694@28.3690278,76.84969439999999"
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                        className='map-box'
                                                    >
                                                        Map
                                                        {/* <img src={Mapcard} alt="Map" style={{ width: '10px', height: '10px' }} /> */}
                                                    </a>
                                                </div>
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Col>

                            </Row>
                            <Row className="d-flex justify-content-center">
                                {/* Card 1 */}
                                <Col lg={4} md={6} sm={12} className="mb-4 d-flex justify-content-center">
                                    <Card className="contact-card">
                                        <Card.Body>
                                            <Row className="align-items-center">
                                                <Col xs={12} className="text-left">
                                                    <Card.Title style={{ fontWeight: 'bold', fontSize: '1.2em', color: '#1C56B2' }}>
                                                        <img src={MAP} alt="Map" style={{ width: '25px', height: '29px', verticalAlign: 'middle' }} />  Mumbai Warehouse
                                                    </Card.Title>
                                                </Col>
                                            </Row>
                                            <Card.Text >
                                                <span style={{ fontWeight: '600', paddingRight: '0.5rem', color: '#000' }}>Address:</span>
                                                Building No. B2 Unit 1 & 2, B4 Unit 10.Bhumi World Industrial Park,Pimplas Village,Bhiwandi, Thane 421302.
                                                <div className='p-0 mt-3' style={{ textAlign: 'right' }}>
                                                    <a
                                                        href="https://www.google.com/maps?ll=19.26572,73.074964&z=15&t=m&hl=en&gl=IN&mapclient=embed&cid=3719751005382366771"
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                        className='map-box'
                                                    >
                                                        Map
                                                        {/* <img src={Mapcard} alt="Map" style={{ width: '10px', height: '10px' }} /> */}
                                                    </a>
                                                </div>
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Col>

                                {/* Card 2 */}
                                <Col lg={4} md={6} sm={12} className="mb-4 d-flex justify-content-center">
                                    <Card className="contact-card">
                                        <Card.Body>
                                            <Row className="align-items-center">
                                                <Col xs={12} className="text-left">
                                                    <Card.Title style={{ fontWeight: 'bold', fontSize: '1.2em', color: '#1C56B2' }}>
                                                        <img src={MAP} alt="Map" style={{ width: '25px', height: '29px', verticalAlign: 'middle' }} />  Kolkata Warehouse
                                                    </Card.Title>
                                                </Col>
                                            </Row>
                                            <Card.Text >
                                                <span style={{ fontWeight: '600', paddingRight: '0.5rem', color: '#000' }}>Address:</span>
                                                Kin Long Hardware (India) Pvt Ltd.Star Battery complex CHAKUNDI DANKUNI HOOGLY West Bengal,Pin,712310, Kolkata.
                                                <div className='p-0 mt-3' style={{ textAlign: 'right' }}>
                                                    <a
                                                        href="https://www.google.com/maps?ll=22.709495,88.293047&z=11&t=m&hl=en&gl=IN&mapclient=embed&cid=15119767894633689262"
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                        className='map-box'
                                                    >
                                                        Map
                                                        {/* <img src={Mapcard} alt="Map" style={{ width: '10px', height: '10px' }} /> */}
                                                    </a>
                                                </div>
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Col>

                                <Col lg={4} md={6} sm={12} className="mb-4 d-flex justify-content-center">
                                </Col>

                            </Row>
                        </Container>
                    </section>
                    {/* WhatsApp Icon */}
                    {/* <div className="whatsapp-icon" onClick={() => window.open('https://wa.me/+919108990181', '_blank')}>
                        <img src={whatsappIcon} alt="WhatsApp" width="60" />
                    </div>
                    <a href="mailto:inhr@kinlong.com" className="email-icon">
                        <img src={emailicon} alt="Email" width="60" />
                    </a> */}
                    {/* <div className="sticky-social">
                        <a href="https://www.facebook.com/KinlongHardwareIndiaPvtLtd/" target="_blank" className="social-link">
                            <FaFacebookF className="icon" style={{ color: '#4267B2', fontSize: '15px' }} />
                        </a>

                        <a href="https://www.instagram.com/kinlong_india/" target="_blank" className="social-link">
                            <FaInstagram className="icon" style={{ color: '#E4405F', fontSize: '15px' }} />
                        </a>

                        <a href="https://www.linkedin.com/company/kinlong-hardware-india-private-limited-official/?viewAsMember=true" target="_blank" className="social-link">
                            <FaLinkedinIn className="icon" style={{ color: '#0077B5', fontSize: '15px' }} />
                        </a>

                        <a href="https://www.youtube.com/@kinlonghardwareindiapvtltd5906" target="_blank" className="social-link">
                            <FaYoutube className="icon" style={{ color: '#FF0000', fontSize: '15px' }} />
                        </a>

                        <a href="https://twitter.com/GKinlongHQ" target="_blank" className="social-link">
                            <img src={twittericon} alt="Twitter" width="60" />
                        </a>
                        <a href="https://www.whatsapp.com/channel/0029Va75kTn5vKA3hzDBAN34" target="_blank" className="social-link">
                            <img src={whatsappbussinesIcon} alt="WhatsApp" width="60" />
                        </a>
                    </div> */}

                    <div
                        className="arrow-icon"
                        style={{
                            position: 'fixed',
                            bottom: '73px',
                            right: '20px',
                            cursor: 'pointer',
                            zIndex: 1000,
                        }}
                    >
                        <Arrow onClick={scrollToTop} />
                    </div>

                    {/* Footer Section */}
                    <section>
                        <Footer />
                    </section>
                </>
            )}
        </div>
    );
};

export default Contact;

import React, { useState, useEffect } from 'react';
import { Navbar, Nav, NavDropdown, Container, Row, Col, Carousel, Card, Tabs, Tab } from 'react-bootstrap';
import { FaFacebookF, FaInstagram, FaLinkedinIn, FaYoutube, FaTwitter } from 'react-icons/fa';
import { Breadcrumb } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import '../styles/Styles.css';
import '../styles/Bootstrap.css';
import emailicon from '../../../images/email.png';
import twittericon from '../../../images/twitter.png';
import whatsappIcon from '../../../images/whatspp.png';
import Privacybg from '../../../images/privacybg01.png';
import dimond from '../../../images/our01.png';
import whatsappbussinesIcon from '../../../images/whastappb01.png';
import about from '../../../images/banner01.png';
import privacy from '../../../images/privcay01.png';
import Arrow from './Arrow';
import Header from '../home/Header';
import Footer from '../home/Footer';


const PrivacyPolicy = () => {
    const [loading, setLoading] = useState(false);
    const [key, setKey] = useState<string>('tab1');

    useEffect(() => {
        const timer = setTimeout(() => {
            setLoading(false);
        }, 1000);

        return () => clearTimeout(timer);
    }, []);

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    return (
        <div className="boxed_wrapper ltr">
            {loading ? (
                <div className="loader-wrap">
                    <div className="preloader">
                        <div id="handle-preloader" className="handle-preloader">
                            <div className="animation-preloader">
                                <div className="spinner"></div>
                                <div className="txt-loading">
                                    {['K', 'I', 'N', 'L', 'O', 'N', 'G'].map((letter, index) => (
                                        <span key={index} data-text-preloader={letter} className="letters-loading">
                                            {letter}
                                        </span>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <>
                    <Header />

                    <div className="breadcrumb-section" style={{ position: 'relative', padding: '75px 0', textAlign: 'center' }}>
                        <div
                            style={{
                                backgroundImage: `url(${Privacybg})`,
                                backgroundSize: 'cover',
                                backgroundPosition: 'center',
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                right: 0,
                                bottom: 0,
                                zIndex: 1,
                            }}
                        ></div>
                        <div
                            style={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                right: 0,
                                bottom: 0,
                                zIndex: 2,
                            }}
                        ></div>
                        <Container fluid style={{ position: 'relative', zIndex: 3 }}>
                            <Breadcrumb className="breadcrumb mt-5">
                                <h1 style={{ color: '#ffffff', textDecoration: 'none', top: 29 }}>

                                </h1>
                            </Breadcrumb>
                        </Container>
                    </div>

                    <section className="Tabsection">
                        <Container>
                            <Card className='cardprivacy'>
                                <Tabs
                                    id="privacy-tabs"
                                    activeKey={key}
                                    onSelect={(k: any) => setKey(k)}
                                    fill
                                    aria-label="Privacy Policy Tabs">
                                    <Tab eventKey="tab1" title="Privacy Policy" tabClassName="custom-policy-tab">
                                        <div className="tab-content">
                                            <section className='about'>
                                                <Container className="mb-5" style={{ marginTop: '2.2rem', paddingBottom: '4rem' }}>
                                                    <div className="ourstory-content">
                                                        <img src={dimond} alt="left image" className="ourstory-image" />
                                                        <h2 className='ourstory-head'>Privacy <span style={{ color: '#005EA7' }}>Policy</span></h2>
                                                        <img src={dimond} alt="right image" className="ourstory-image" />
                                                    </div>
                                                    <Row>
                                                        <Col md={12} className="d-flex">
                                                            <div>
                                                                <p className='aboutpara'>At Kin Long, we care about the privacy of our visitors, and we are committed to always protecting your privacy and data. All the users must read and understand this Privacy Policy as it has been formulated to safeguard the user’s privacy. This Privacy Policy also outlines the ways the users can ensure protection of their personal identifiable information. You must accept the contents of this Policy to use or continue using our site. This Privacy Policy detailed herein is also applicable to user of the site or mobile application through mobile or any other similar device.</p>
                                                                <p className='aboutpara'>We do not collect any data or your personal information, discreetly. Wherever, requisite information about your demographics or preference is needed, the same is collected through fillable data entry fields available on the website. It is not our policy to collect any personal data without first advising you of this. You will always know when you are providing personal data to us. For example, if you fill in a form or make an order from this website, we will need to take certain information from you in order to complete the requisite details and process your order.</p>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <p className='aboutpara'>We will use any data that you provide to us to notify you of offers or products that may be of interest to you but at no time will we provide your data to third parties for their marketing activities. In case you wish to stop these alerts, you may simply opt out by writing back to us of your desire to not be included in the mailing list.</p>
                                                    <p className='aboutpara'>The security of all information that we collect, and store is critical, and we observe strict data security guidelines to make sure that all data is stored and processed securely. If you have any questions or queries regarding our privacy policy use and storage of data, please do not hesitate to contact us by email at <a href="mailto:inhr@kinlong.com" style={{ color: '#225287' }}>inhr@kinlong.com.</a></p>
                                                    <p className='aboutpara'><b>Changes in Privacy Policy:</b></p>
                                                    <p className='aboutpara'>The policy may change from time to time, so users are requested to check it periodically.</p>
                                                </Container>
                                            </section>

                                        </div>
                                    </Tab>

                                    <Tab eventKey="tab2" title="Terms & Conditions" tabClassName="custom-policy-tab">
                                        <div className="tab-content">
                                            <section className='about'>
                                                <Container className="mb-5" style={{ marginTop: '2.2rem', paddingBottom: '4rem' }}>
                                                    <div className="ourstory-content">
                                                        <img src={dimond} alt="left image" className="ourstory-image" />
                                                        <h2 className='ourstory-head'>Terms & <span style={{ color: '#005EA7' }}>Conditions</span></h2>
                                                        <img src={dimond} alt="right image" className="ourstory-image" />
                                                    </div>
                                                    <Row className="justify-content-center">
                                                        <Col md={12} className="d-flex mt-3">
                                                            <div>
                                                                <h6>PLEASE READ THE FOLLOWING TERMS AND CONDITIONS OF USE AGREEMENT CAREFULLY</h6>
                                                                <p className='aboutpara'>Kinlong Hardware (India) Private Limited is involved in sales of Construction
                                                                    This website is operated by Kinlong Hardware India Pvt Ltd. Throughout the site, the terms “we”, “us” and “our” refer to Kinlong Hardware India Pvt Ltd. offers this website, including all information, tools and services available from this site to you, the user, conditioned upon your acceptance of all terms, conditions, policies and notices stated here.</p>
                                                                <p className='aboutpara'>By visiting our site and/ or purchasing something from us, you engage in our “Service” and agree to be bound by the following terms and conditions (“Terms of Service”, “Terms”), including those additional terms and conditions and policies referenced herein and/or available by hyperlink. These Terms of Service apply to all users of the site, including without limitation users who are browsers, vendors, customers, merchants, and/ or contributors of content.</p>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <p className='aboutpara'>Please read these Terms of Service carefully before accessing or using our website. By accessing or using any part of the site, you agree to be bound by these Terms of Service. If you do not agree to all the terms and conditions of this agreement, then you may not access the website or use any services. If these Terms of Service are considered an offer, acceptance is expressly limited to these Terms of Service.</p>
                                                    <p className='aboutpara'>Any new features or tools which are added to the current website shall also be subject to the Terms of Service. You can review the most current version of the Terms of Service at any time on this page. We reserve the right to update, change or replace any part of these Terms of Service by posting updates and/or changes to our website. It is your responsibility to check this page periodically for changes. Your continued use of or access to the website following the posting of any changes constitutes acceptance of those changes.</p>
                                                </Container>
                                            </section>
                                        </div>
                                    </Tab>

                                    <Tab eventKey="tab3" title="POSH" tabClassName="custom-policy-tab">
                                        <div className="tab-content">
                                            <section className='about'>
                                                <Container className="mb-5" style={{ marginTop: '2.2rem', }}>
                                                    <div className="ourstory-content">
                                                        <img src={dimond} alt="left image" className="ourstory-image" />
                                                        <h2 className='ourstory-head'>POS<span style={{ color: '#005EA7' }}>H</span></h2>
                                                        <img src={dimond} alt="right image" className="ourstory-image" />
                                                    </div>
                                                    <Row className="justify-content-center">
                                                        <Col md={12} className="d-flex mt-3">
                                                            <div>
                                                                <h5> KL India Policy on Prevention of Sexual Harassment (POSH) of women at workplace</h5>
                                                                <p className='aboutpara'>Kinlong Hardware India Private Limited has a zero-tolerance policy for any act of sexual harassment of any kind and any act of suppressing or distortion of such facts or evidence and retaliation. Kinlong Hardware India Private Limited is committed to take all necessary steps in ensuring that its staff works in a safe and respectful environment that is conducive to their professional growth and promotes equality of opportunity.</p>
                                                                <p className='aboutpara'>This policy applies to all employees (full-time, part-time, trainees and those on contractual assignments) of Kinlong Hardware India Private Limited, including all subsidiaries and affiliated companies at their workplace or at client sites. The Company will not tolerate sexual harassment, if engaged in by clients or by suppliers or any other business associates.</p>
                                                                <p className='aboutpara'><b>The workplace includes:</b></p>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <p className='aboutpara'>All offices or other premises where Kinlong’s business is conducted. All company-related activities are performed at any other site away from the Company’s premises. Any social, business, Team Outing, Meeting or other functions where the conduct or comments may have an adverse impact on the workplace or workplace relations. Sexual harassment may be one or a series of incidents involving unsolicited and unwelcome sexual advances, requests for sexual favors, or any other verbal or physical conduct of sexual nature.</p>
                                                    <p className='aboutpara'><b>Sexual Harassment at the workplace includes:</b></p>
                                                    <p className='aboutpara'>a) Unwelcome sexual advances (verbal, written, or physical, Demand or request for sexual favors, Bad touch)</p>
                                                    <p className='aboutpara'>b) Any other type of sexually oriented conduct, </p>
                                                    <p className='aboutpara'>c) Verbal abuse or ‘joking’ that is sex-oriented,</p>
                                                    <p className='aboutpara'>d) any conduct that has the purpose or the effect of interfering with an
                                                        individual’s work performance or creating an intimidating, hostile or offensive
                                                        work environment and/or submission to such conduct is either an explicit or
                                                        implicit term or condition of employment and /or submission or rejection of
                                                        the conduct is used as a basis for making employment decisions. </p>
                                                    <p className='aboutpara'>e)Inappropriate conduct could also be a joke, a prank or even a compliment.
                                                        These gestures also lead to harassment although the intention of the individual
                                                        might not be to offend the other person.</p>
                                                </Container>
                                            </section>

                                            <section>
                                                <Container>
                                                    <h5>RESPONSIBILITIES REGARDING SEXUAL HARASSMENT:</h5>
                                                    <p className='aboutpara'>a)All employees of the Company have a personal responsibility to ensure that their behavior is not contrary to this policy.</p>
                                                    <p className='aboutpara'>b)All employees are encouraged to reinforce the maintenance of a work environment free from sexual harassment.</p>
                                                    <p className='aboutpara'><b>Policy Statement: -</b>The Prevention of Sexual Harassment of Women at Workplace (Prevention, Prohibition and Redressal) Act, 2013 read along with its Rules of 2013, (the “Act”) states that no employee shall be subject to Sexual Harassment at any Workplace.</p>
                                                    <p className='aboutpara'><b>POSH Committee: -</b>Kinlong India will be <b>constituted</b> by an order of <b><span style={{ color: '#e90909' }}>I</span>nternal <span style={{ color: '#e90909' }}>C</span>omplaint <span style={{ color: '#e90909' }}>C</span>ommittee </b>(ICC) under Sexual Harassment at Workplace (Prevention, Prohibition and Redressal) Act, 2013 preferably headed by a woman member of the organization. Internal Complaints Committee (Henceforth known as ‘committee’).</p>
                                                    <p className='aboutpara'>So we have formed an ICC as per the act and below are the committee members identified along with an External Committee member and their contact information, Mail ID updated for all employees reference.</p>
                                                </Container>
                                            </section>

                                            <section className='table'>
                                                <Container className='mt-5'>
                                                    <h5>POSH Committee Members</h5>
                                                    <div className="table-responsive" style={{ padding: '0.8rem 2rem 4rem 2rem' }}>
                                                        <table className="table table-bordered table-striped">
                                                            <thead>
                                                                <tr>
                                                                    <th style={{ color: '#fff' }}>S.NO</th>
                                                                    <th style={{ color: '#fff' }}>Emp.No</th>
                                                                    <th style={{ color: '#fff' }}>Employee Name</th>
                                                                    <th style={{ color: '#fff' }}>Location</th>
                                                                    <th style={{ color: '#fff' }}>Designations</th>
                                                                    <th style={{ color: '#fff' }}>E-Mail ID</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <td>1</td>
                                                                    <td>224</td>
                                                                    <td>MS.SKAKTHI SHREE D</td>
                                                                    <td>BANGALORE</td>
                                                                    <td>Presiding Officer (Chair Person)</td>
                                                                    <td>shree.skakthi@kinlong.cn</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>2</td>
                                                                    <td>223</td>
                                                                    <td>MS.SHREEKALA T</td>
                                                                    <td>BANGALORE</td>
                                                                    <td>Emp.Member</td>
                                                                    <td>t.shreekala@kinlong.cn</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>3</td>
                                                                    <td>432</td>
                                                                    <td>MS.SHREENIDHI NAYAK</td>
                                                                    <td>BANGALORE</td>
                                                                    <td>Officer(Administration)</td>
                                                                    <td>nayak.shreenidhi@kinlong.cn	</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>4</td>
                                                                    <td>083</td>
                                                                    <td>MR. PRAVEEN DEVADIGA</td>
                                                                    <td>BANGALORE</td>
                                                                    <td>Emp.Member</td>
                                                                    <td>inhr@kinlong.com</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>5</td>
                                                                    <td>455</td>
                                                                    <td>MS.ARYA MOHAN</td>
                                                                    <td>KOCHIN</td>
                                                                    <td>Emp.Member</td>
                                                                    <td>mohan.arya@kinlong.cn</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>6</td>
                                                                    <td>174</td>
                                                                    <td>MS.NISHA</td>
                                                                    <td>GURGAON</td>
                                                                    <td>Emp.Member</td>
                                                                    <td>rattan.nisha@kinlong.cn</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>7</td>
                                                                    <td>409</td>
                                                                    <td>MS.PRERNA</td>
                                                                    <td>GURGAON</td>
                                                                    <td>Emp.Member</td>
                                                                    <td>sign.prerna@kinlong.cn</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>8</td>
                                                                    <td>299</td>
                                                                    <td>MS.NEHA SUNIL MISHRA</td>
                                                                    <td>MUMBAI</td>
                                                                    <td>Emp.Member</td>
                                                                    <td>mishra.neha@kinlong.cn</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>9</td>
                                                                    <td>076</td>
                                                                    <td>MR.SAUGANDH RADHAKRISHNAN</td>
                                                                    <td>MUMBAI</td>
                                                                    <td>Emp.Member</td>
                                                                    <td>saugandh@kinlong.cn</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>10</td>
                                                                    <td>NA</td>
                                                                    <td style={{ color: '#e60a0a' }}>MR.GANESH POOJARY</td>
                                                                    <td style={{ color: '#e60a0a' }}>BLR - External</td>
                                                                    <td style={{ color: '#e60a0a' }}>External Member</td>
                                                                    <td>ganesh@kgpaiandco.com</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </Container>
                                            </section>
                                        </div>
                                    </Tab>
                                </Tabs>
                            </Card>
                        </Container>
                    </section>

                    {/* WhatsApp Icon */}
                    {/* <div className="whatsapp-icon" onClick={() => window.open('https://wa.me/+919108990181', '_blank')}>
                        <img src={whatsappIcon} alt="WhatsApp" width="60" />
                    </div>
                    <a href="mailto:inhr@kinlong.com" className="email-icon">
                        <img src={emailicon} alt="Email" width="60" />
                    </a> */}
                    <div
                        className="arrow-icon"
                        style={{
                            position: 'fixed',
                            bottom: '73px',
                            right: '20px',
                            cursor: 'pointer',
                            zIndex: 1000,
                        }}
                    >
                        <Arrow onClick={scrollToTop} />
                    </div>

                    {/* <div className="sticky-social">
                        <a href="https://www.facebook.com/KinlongHardwareIndiaPvtLtd" target="_blank" className="social-link">
                            <FaFacebookF className="icon" style={{ color: '#4267B2', fontSize: '15px' }} />
                        </a>

                        <a href="https://www.instagram.com/kinlong_india" target="_blank" className="social-link">
                            <FaInstagram className="icon" style={{ color: '#E4405F', fontSize: '15px' }} />
                        </a>

                        <a href="https://www.linkedin.com/in/kinlong-india-766b98198" target="_blank" className="social-link">
                            <FaLinkedinIn className="icon" style={{ color: '#0077B5', fontSize: '15px' }} />
                        </a>

                        <a href="https://www.youtube.com/@kinlonghardwareindiapvtltd5906" target="_blank" className="social-link">
                            <FaYoutube className="icon" style={{ color: '#FF0000', fontSize: '15px' }} />
                        </a>

                        <a href="https://twitter.com/GKinlongHQ" target="_blank" className="social-link">
                            <img src={twittericon} alt="Twitter" width="60" />
                        </a>
                        <a href="https://www.whatsapp.com/channel/0029Va75kTn5vKA3hzDBAN34" target="_blank" className="social-link">
                            <img src={whatsappbussinesIcon} alt="WhatsApp" width="60" />
                        </a>
                    </div> */}

                    {/* Footer Section */}
                    <section>
                        <Footer />
                    </section>

                </>
            )}
        </div>
    );
};

export default PrivacyPolicy;
